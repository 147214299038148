import React, { useCallback } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import { notifService } from '../../machines/notif.machine';
import type {
  Color2 as ColorGlobal,
  Placement2Axis,
} from '../../types/component.type';
import './write2.component.scss';

export declare namespace Write2Type {
  type Props = {
    className?: string;
    handleEvent?: {
      click?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
      over?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    };
    children: string | number;
    config?: {
      wrap?: boolean;
      mode?:
        | 'x-large-title'
        | 'large-title'
        | 'title-1'
        | 'title-2'
        | 'title-3'
        | 'headline'
        | 'button'
        | 'body'
        | 'subhead'
        | 'link'
        | 'name'
        | 'label'
        | 'headcaption'
        | 'caption'
        | 'sublabel';
      color?: Write2Type.Config.Color;
      align?: Extract<Placement2Axis, 'left' | 'center' | 'right'>;
      copyPaste?: boolean;
      lineHeight?: boolean;
      testId?: string;
    };
  };

  namespace Config {
    type Color = Extract<
      ColorGlobal,
      | 'deep_indigo'
      | 'deep_indigo_70'
      | 'deep_indigo_50'
      | 'deep_indigo_30'
      | 'deep_indigo_10'
      | 'deep_indigo_4'
      | 'soft_teal'
      | 'soft_teal_70'
      | 'soft_teal_50'
      | 'soft_teal_30'
      | 'soft_teal_10'
      | 'black_90'
      | 'black_70'
      | 'black_50'
      | 'black_15'
      | 'black_4'
      | 'black_2'
      | 'alert_success'
      | 'alert_success_50'
      | 'alert_warning'
      | 'alert_warning_50'
      | 'alert_advisory'
      | 'alert_advisory_50'
      | 'alert_error'
      | 'alert_error_50'
      | 'alert_info'
      | 'alert_info_50'
    >;
  }
}

export const Write2 = ({
  className = '',
  handleEvent: { click, over } = {},
  children,
  config: {
    mode = 'body',
    color,
    wrap = true,
    align = 'left',
    copyPaste = false,
    testId,
    lineHeight = true,
  } = {},
}: Write2Type.Props) => {
  const { t, lang } = useTranslation();

  const handleCopyPaste = useCallback(
    (item: string) => {
      navigator.clipboard.writeText(item).then(() => {
        notifService.send('NOTIF', {
          mode: 'success',
          content: t('watermelon-copyToClipboard'),
        });
      });
    },
    [lang],
  );

  return (
    <div
      className={`write2 
        write2--mode--${mode} 
        write2--color--${color} 
        ${!lineHeight ? 'write2--noLineHeight' : ''}
        ${copyPaste ? 'write2--copyPaste' : ''}
        ${className}`}
      style={{
        textAlign: align,
        whiteSpace: !wrap ? 'nowrap' : undefined,
        cursor: click || over || copyPaste ? 'pointer' : undefined,
      }}
      onClick={(e) => {
        if (click) click?.(e);
        if (copyPaste) handleCopyPaste(`${children}`);
      }}
      onMouseOver={(e) => over?.(e)}
      data-testid={testId}
    >
      {copyPaste && (
        <div className="write2__copyPaste">
          <span translate="no">{t('watermelon-copyTheContent')}</span>
        </div>
      )}
      {['x-large-title'].includes(mode) && (
        <h1 className="write2__item">{children}</h1>
      )}
      {['large-title'].includes(mode) && (
        <h2 translate="no" className="write2__item">
          {children}
        </h2>
      )}
      {['title-1'].includes(mode) && (
        <h3 translate="no" className="write2__item">
          {children}
        </h3>
      )}
      {['title-2'].includes(mode) && (
        <h4 translate="no" className="write2__item">
          {children}
        </h4>
      )}
      {['title-3'].includes(mode) && (
        <h5 translate="no" className="write2__item">
          {children}
        </h5>
      )}
      {[
        'headline',
        'button',
        'subhead',
        'link',
        'label',
        'headcaption',
        'caption',
        'sublabel',
        'name',
      ].includes(mode) && (
        <span translate="no" className="write2__item">
          {children}
        </span>
      )}
      {['body'].includes(mode) && (
        <p translate="no" className="write2__item">
          {children}
        </p>
      )}
    </div>
  );
};
