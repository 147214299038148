import { useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import MainUse from './main';
import { ProductDigitalPage } from './pages/product-digital/view/product-digital.page';

export const CustomRouter = observer(() => {
  const { SSOStore } = useRootStore();

  return SSOStore.authenticated ? (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '*',
          element: <Navigate to={`/`} replace />,
        },

        {
          path: '/',
          element: <MainUse />,
          children: [
            {
              index: true,
              element: <Navigate to={`/product/digital`} replace />,
            },
            {
              path: 'product',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={`${window.location.pathname}/product/digital`}
                      replace
                    />
                  ),
                },
                {
                  path: 'digital',
                  element: <ProductDigitalPage />,
                },
              ],
            },
          ],
        },
      ])}
    ></RouterProvider>
  ) : null;
});
