import { Icon, Write2 } from '@gimlite/watermelon';
import dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import './module.component.scss';

dayjs.extend(duration as any);

export declare namespace ProductDigitaType {
  type Props = {
    className?: string;

    data: {
      label: string;
      description: string;
      title: string;
      color: string;
      url: string;
    };
    config: {
      isActive: boolean;
    };
  };
}

export const Module = ({
  className = '',
  data,
  config: { isActive },
}: ProductDigitaType.Props) => {
  const { label, title, description, color, url } = data;
  return (
    <div
      style={{ backgroundColor: color }}
      onClick={() => {
        if (isActive) window.location.replace(url);
      }}
      className={`module ${
        isActive ? 'module--active' : 'module--inactive'
      } ${className}`}
    >
      <div className="module__present">
        <div className="module__present__intro">
          <Write2 config={{ mode: 'label', color: 'black_90' }}>
            {label.toUpperCase()}
          </Write2>
          <Icon
            config={{ type: 'faEllipsisVerticalSolid', color: 'black_90' }}
          ></Icon>
        </div>
        <Write2 config={{ mode: 'title-1', color: 'black_90' }}>{title}</Write2>
        <Write2 config={{ mode: 'body', color: 'black_90' }}>
          {description}
        </Write2>
      </div>

      <div className="module__action">
        <Icon
          config={{
            type: 'horodatorSmartSolution',
            size: 'giga',
            color: 'black_90',
          }}
        ></Icon>
        {isActive && (
          <div className="module__action__button">
            <Icon
              className="module__action__button__icon"
              config={{
                type: 'faArrowRightLongSolid',
                color: 'white',
                size: 'small',
              }}
            ></Icon>
          </div>
        )}
      </div>
    </div>
  );
};
