import { Config } from '@gimlite/watermelon';
import { createRoot } from 'react-dom/client';
import { CustomRouter } from './CustomRouter';
import config from './config';
import Keycloak from 'keycloak-js';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Config
    debug={false}
    version={{
      env: config.ENV,
      front: config.VERSION_FRONT,
    }}
    server={{
      bff: config.BFF_HOST,
      ws: config.BFF_WS_HOST,
    }}
    account={{
      updatePassword: true,
    }}
    isResponsive={false}
    authorization={config.BFF_TOKEN}
    theme={{
      color: {
        dark: '#5dbed2',
        light: '#0a2652',
      },
      defaultTheme: 'light',
    }}
    wizards={[]}
    sso={{
      mode: { type: 'parent' },
      instance: new Keycloak({
        url: config.SSO_URL,
        realm: config.SSO_REALM,
        clientId: config.SSO_CLIENT_ID,
      }),
    }}
  >
    <CustomRouter />
  </Config>,
);
